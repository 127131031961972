import { isDevelopment } from '@/utilities/is-development.function';
import axios from 'axios';
import { $environment } from './environment.service';
import { $user } from './user.service';
import { $webApi } from './web-api.service';
import router from '../router';

class SystemService {
    private heartBeatInterval?: number;
    private heartRate: number = 30 * 1000; // default 30s interval
    private get isActive(): boolean {
        return !!this.heartRate && this.heartRate > 0;
    }

    track() {
        if (this.heartBeatInterval || !this.isActive) {
            return;
        }
        this.startHeartBeat();
    }

    stopTracking() {
        this.killHeartBeat();
    }

    updateHeartRate(rate: number) {
        if (this.heartRate === rate) {
            return;
        }
        this.heartRate = rate;

        if (!this.isActive) {
            this.killHeartBeat();
        } else {
            this.startHeartBeat();
        }
    }


    private startHeartBeat() {
        window.clearInterval(this.heartBeatInterval);

        this.sendHeartBeat();
        this.heartBeatInterval = window.setInterval(() => this.sendHeartBeat(), this.heartRate);
    }

    private killHeartBeat() {
        if (!this.heartBeatInterval) {
            return;
        }
        window.clearInterval(this.heartBeatInterval);
        this.heartBeatInterval = undefined;
    }

    private async sendHeartBeat() {
        // note: as we call it as soon as navigation occurs make sure env is ready
        await $environment.whenReady();

        const heartSign = '\u{1F493}';
        if (isDevelopment()) {
            console.log(`[${new Date()}]`, heartSign, 'HeartBeat');
        }

        try {
            await $webApi.POST.UserSession.HeartBeat();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const { response } = error;

                if (response?.status === 403 || response?.status === 401) {
                    return this.handleExpireToken();
                }   
            } 
            
            if (isDevelopment()) {
                console.error(`[${new Date()}]`, heartSign, `Unhandled error: ${error} (HeartBeat)`);
            }
        }       
    }

    private async handleExpireToken() {
        // note: clear interval before loging out to not trigger additional HeartBeat request
        window.clearInterval(this.heartBeatInterval);
        this.heartBeatInterval = undefined;

        await $user.logout();

        router.push({ name: 'login' });
    }
}

export const $system = new SystemService();
