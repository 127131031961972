var render, staticRenderFns
import script from "./BaseModal.vue?vue&type=script&lang=ts"
export * from "./BaseModal.vue?vue&type=script&lang=ts"
import style0 from "./BaseModal.vue?vue&type=style&index=0&id=746244e7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "BaseModal.vue"
export default component.exports