import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';

@Module({ dynamic: true, store, name: 'filterState' })
class filtersState extends VuexModule {
    [key: string]: any;
    Stockes:any = null;
    Taskes:any = null;
    Draftes:any = null;
    IngoingOrderes:any = null;
    Inventoryes:any = null;
    Jobes:any = null;
    Locationes:any= null;
    Supplieres:any= null;
    Maintenancees:any = null;
    Materiales:any= null;
    Operationes:any= null;
    OutgoingOrderes:any= null;
    SalesOrderDraftes:any= null;

    @Mutation
    setJobesFilters(dxState: any): void {
        this.Jobes = dxState;
    }

    @Mutation
    setLocationesFilters(dxState: any): void {
        this.Locationes = dxState;
    }

    @Mutation
    setMaintenanceesFilters(dxState: any): void {
        this.Maintenancees = dxState;
    }
    @Mutation
    setSupplieresFilters(dxState: any): void {
        this.Supplieres = dxState;
    }

    @Mutation
    setInventoryesFilters(dxState: any): void {
        this.Inventoryes = dxState;
    }
    @Mutation
    setDraftesFilters(dxState: any): void {
        this.Draftes = dxState;
    }

    @Mutation
    setMaterialesFilters(dxState: any): void {
        this.Materiales = dxState;
    }

    @Mutation
    setOperationesFilters(dxState: any): void {
        this.Operationes = dxState;
    }

    @Mutation
    setIngoingOrderesFilters(dxState: any): void {
        this.IngoingOrderes = dxState;
    }

    @Mutation
    setOutgoingOrderesFilters(dxState: any): void {
        this.OutgoingOrderes = dxState;
    }

    @Mutation
    setStockesFilters(dxState: any): void {
        this.Stockes = dxState;
    }

    @Mutation
    setTaskesFilters(dxState: any): void {
        this.Taskes = dxState;
    }
    
    @Mutation
    setSalesOrderDraftesFilters(dxState: any): void {
        this.SalesOrderDraftes = dxState;
    }

    @Mutation
    resetFilters(dxState: any): void {
        this[dxState] = [];
    }

    get getJobesFilters(): any {
        return this.Jobes;
    }
    get getStockesFilters(): any {
        return this.Stockes;
    }
    get getLocationesFilters(): any {
        return this.Locationes;
    }
    get getSupplieresFilters(): any {
        return this.Supplieres;
    }
    get getIngoingOrderesFilters(): any {
        return this.IngoingOrderes;
    }
    get getOutgoingOrderesFilters(): any {
        return this.OutgoingOrderes;
    }
    get getTaskesFilters(): any {
        return this.Taskes;
    }
    get getMaintenanceesFilters(): any {
        return this.Maintenancees;
    }
    get getDraftesFilters(): any {
        return this.Draftes;
    }
    get getInventoryesFilters(): any {
        return this.Inventoryes;
    }

    get getMaterialesFilters(): any {
        return this.Materiales;
    }
    get getOperationesFilters(): any {
        return this.Operationes;
    }
    
    get getSalesOrderDraftesFilters(): any {
        return this.SalesOrderDraftes;
    }

    @Mutation
    dispose(): void {
        this.Stockes = null;
        this.Taskes = null;
        this.Draftes = null;
        this.IngoingOrderes = null;
        this.Inventoryes = null;
        this.Jobes = null;
        this.Locationes = null;
        this.Supplieres = null;
        this.Maintenancees = null;
        this.Materiales = null;
        this.Operationes = null;
        this.OutgoingOrderes = null;
        this.SalesOrderDraftes = null;
    }

    @Action
    async reset(val: any) {
        this['resetFilters'](val);
    }
    
    @Action
    async applyFilter(val: any) {
        this[`set${val.view}Filters`](val.filter);
    }
}

export const filtersStateModule = getModule(filtersState);
