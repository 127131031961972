import { cookieService } from '@/services/cookie.service';
import ObjectHelper from '@/utilities/object.helper';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { II18NState } from '../states/i18n-state.interface';
import { settingsModule } from './settings.module';
import { userModule } from './user.module';

@Module({ dynamic: true, store, name: 'i18N' })
class I18NModule extends VuexModule implements II18NState {
    langCode: string = '';
    translation: any = null;
    translations: Map<string, any> = new Map();

    get translationsReady(): boolean {
        return this.langCode !== '' && !!this.translation;
    }

    @Mutation
    setLang(payload: { langCode: string, lang: any }): void {
        const { langCode, lang } = payload;

        const translation = ObjectHelper.deepClone(lang);
        
        this.translations.set(langCode, translation);

        if (!this.translationsReady) {
            this.langCode = langCode;
            
            this.translation = translation;
            
            // note: store selected language in cookie to be available after page refresh
            settingsModule.setDefaultLanguageCookie(langCode);

            const { user } = userModule;
            if (user !== null && user !== undefined) {
                user.defaultLanguage = langCode;
                cookieService.addCookie(cookieService.keys.USER, user);
            }
        }
    }

    @Mutation
    changeLang(langCode: string): void {
        this.langCode = langCode;
        
        this.translation = this.translations.get(langCode);
    }

    @Action
    async has(langCode: string): Promise<boolean> {
       return this.translations.has(langCode);
    }
}

export const i18NModule = getModule(I18NModule);
