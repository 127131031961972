import { IDxGrid } from '@/interfaces/dx-grid.interface';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

export default class DxDataService {
    private dxDataGrid: IDxGrid;
    private readonly historicalDelay: number = 5000; // ms

    private get dxDataStore(): ArrayStore {
        const ds = this.dxDataGrid?.getDataSource();
        
        return ds ? ds.store() as ArrayStore : new ArrayStore({
            data: [],
            key: 'id'
        });
    }
    
    constructor(dataGrid: any) {
        this.dxDataGrid = dataGrid;
    }

    async changeData<T>(data: T | T[], showHistorical: boolean, resetGrid: boolean = true) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        let store: ArrayStore;
        if (data.length > 1000) {
            // note: due to ineficiency recreate store instead of handling data one by one
            store = new ArrayStore({
                data,
                key: 'id'
            });
        } else {
            store = this.dxDataStore;

                data.forEach((row: any) => {
                    this.handleReceivedData(store, row, showHistorical);
                });
        }

        if (resetGrid) {
            this.updateDataSource(store);
        } else {
            const grid = this.dxDataGrid;
            const expandedAdaptiveViewRows = grid.getVisibleRows().filter(row => {
                return grid.isAdaptiveDetailRowExpanded(row.key);
            });

            const dataSource = grid.getDataSource();
            if (!dataSource) {
                return;
            }

            dataSource.reload().then(() => {
                // when adding new entries it's possible that currently expanded row
                // will be moved to other page, in such a case grid automatically
                // closes adaptive view, so we need to restore it manually
                if (expandedAdaptiveViewRows.length) {
                    expandedAdaptiveViewRows.forEach(row => {
                        if (!grid.isAdaptiveDetailRowExpanded(row.key)) {
                            grid.expandAdaptiveDetailRow(row.key);
                        }
                    })
                }
            });
        }
    }

    async bulkChangeData<T>(data: T[], showHistorical: boolean) {
        const grid = this.dxDataGrid;
        const expandedAdaptiveViewRows = grid.getVisibleRows().filter(row => {
            return grid.isAdaptiveDetailRowExpanded(row.key);
        });
        const store = this.dxDataStore;
        
        data.forEach((group: any) => {
            group.forEach((row: any) => {
                this.handleReceivedData(store, row, showHistorical);
            });
        });

        const dataSource = grid.getDataSource();
        if (!dataSource) {
            return;
        }

        dataSource.reload()
            .then(() => {
                // when adding new entries it's possible that currently expanded row
                // will be moved to other page, in such a case grid automatically
                // closes adaptive view, so we need to restore it manually
                if (expandedAdaptiveViewRows.length) {
                    expandedAdaptiveViewRows.forEach(row => {
                        if (!grid.isAdaptiveDetailRowExpanded(row.key)) {
                            grid.expandAdaptiveDetailRow(row.key);
                        }
                    })
                }
            })
            .catch((err) => {
                // this might happen when we call reload() from different place
            });
    }
    
    async replaceData<T>(data: T | T[], key: string = 'id') {
        const store = new ArrayStore({
            data: [],
            key
        });

        if (!Array.isArray(data)) {
            data = [data];
        }
            
        data.forEach((row: T) => {
            store.insert(row);
        });

        this.updateDataSource(store);
    }
    
    async removeData<T>(data?: T, id?: string) {
        const grid = this.dxDataGrid;
        const store = this.dxDataStore;
        
        grid.beginUpdate();
        
        if (id) {
            store.remove(id); 
        } else if (Array.isArray(data)) {
            data.forEach(row => {
                store.remove(row.id); 
            });
        }
        
        const dataSource = grid.getDataSource();
        if (!dataSource) {
            return;
        }

        dataSource.reload()
            .then(() => {
                grid.endUpdate();
            });
    }

    async bulkRemoveData<T>(data: T[]) {
        const store = this.dxDataStore;

        data.forEach(row => {
            store.remove(row);
        });

        const grid = this.dxDataGrid;
        const dataSource = grid.getDataSource();
        if (!dataSource) {
            return;
        }

        dataSource.reload()
            .then(() => {
                // reloaded successfully
            })
            .catch((err) => {
                // this might happen when we call reload() from different place
            });
    }
    
    removeAll() {
        const store = this.dxDataStore;

        store.clear();
        store.load();
    }
    
    private updateDataSource(store: ArrayStore) {
        // note: store current state
        const state = this.dxDataGrid.state();

        store.load();
        const dataSource = new DataSource({ store });

        this.dxDataGrid.option('dataSource', dataSource); 

        // note: restore state after DataSource update
        this.dxDataGrid.state(state);

        // note: set READY flag to false to trigger proper onContentReady behavior
        this.dxDataGrid.READY = false;
    }
    
    private handleReceivedData(store: ArrayStore, row: any, showHistorical: boolean) {
        if (!row || !row.id) { return; }

        store.byKey(row.id)
            .then(exist => {
                store.update(row.id, row);
            })
            .catch(notExist => {
                store.insert(row);
            });

        if (!showHistorical && row.historical) {
            this.scheduleRemove(row.id);
        }
    }
    
    private scheduleRemove(id: string) {
        window.setTimeout(() => {
            this.removeData(null, id);
        }, this.historicalDelay);
    }
}
