







import { Component, Prop } from 'vue-property-decorator';
import { ICustomModalConfig } from '@/interfaces/custom-modal.interface';
import BaseModal from '@/components/modals/BaseModal.vue';

@Component({})
export default class DynamicModal extends BaseModal<ICustomModalConfig> {
    component: any = null;
    
    async mounted() {
        try {
            await this.loader();

            this.component = () => this.loader();
        } catch (error) {
            this.$modal.showError(new Error('Template for dynamic modal not found!'));

            if (this.config.id !== undefined) {
                this.$modal.close(this.config.id);
            } else {
                this.$modal.closeByIndex(0);
            }
        }
    }
    
    loader() {
        if (!this.config || !this.config.template) {
            return null;
        }

        return import(`@/components/modals/templates/${this.config.template}.vue`);       
    }
}

