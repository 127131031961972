
import { settingsModule } from '@/store/modules/settings.module';
import { signalRModule } from '@/store/modules/signalR.module';
import { SignalREvent } from '@/utilities/events.extensions';
import { SignalRMsg } from '@/utilities/translation.enum';
import { HttpError, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { StatusCodes } from 'http-status-codes';
import { SignalRError } from '../utilities/error.extensions';
import { $modalService } from './custom-modal.service';
import { $environment } from './environment.service';
import { $settingsService } from './settings.service';
import { $user } from './user.service';

class SignalRService {
    private isEnabled: boolean = false;
    private activeSubscriptions: Map<string, string[]> = new Map(); // active subscriptions (resets on disconnect)
    private subscriptionsHistory: Map<string, string[]> = new Map(); // all subscriptions (resets only on page refresh)

    private connection: HubConnection | null = null;
    private reconnectCounter: number = 0;
    private reconnectTimeout = $environment.signalRConfig.reconnectTimeout || 1000;
    private connectionOptions = {
        eventName: '',
        callback: null
    };

    private connectionPromise: any;
    private connectionResolve: any;
    private connectionReject: any;

    private get authToken(): string {
        let token: string = '';

        if (signalRModule.isAzureSignalR) {
            token = signalRModule.signalRToken;
        } else {
            // todo: verify if not needed anymore
            token = settingsModule.token;

            token = token ? token.replace('Bearer ', '') : '';
        }

        return token;
    }

    private get url(): string {
        const url = signalRModule.signalREndpoint;

        if (signalRModule.isAzureSignalR) {
            // const temp_url = 'https://apportsignalr.service.signalr.net'
            return `${url}/client/?hub=viewHub`;
        }

        return `${url}/view`;
    }

    private get activeConnection() {
        if (this.connection) {
            return this.connection;
        }
        throw new SignalRError('Connection not available');
    }

    isActiveSubscription(signalRViewName: string): boolean {
        return this.activeSubscriptions.has(signalRViewName);
    }

    async init(callback: any, eventName: string, viewName: string, viewTypes: string[]) {
        if (!callback || !eventName || !viewName) {
            return;
        }

        try {
            this.isEnabled = true;

            await this.tryConnect(callback, eventName);

            await this.register(viewName, viewTypes);
        } catch (errorMsg) {
            $modalService.showSignalRModal(errorMsg as string);
        }
    }

    async delete(signalRViewName: string) {
        if (!this.activeSubscriptions.has(signalRViewName)) {
            return;
        }

        const types = this.activeSubscriptions.get(signalRViewName);
        if (types) {
            await this.unregister(signalRViewName, types);
        }

        if (this.activeSubscriptions.size === 0) {
            await this.dispose();
        }
    }

    async dispose() {
        this.isEnabled = false;

        if (this.activeSubscriptions.size) {
            try {
                for (const [name, types] of this.activeSubscriptions) {
                    await this.unregister(name, types);
                }
            } catch (error) {
                this.activeSubscriptions = new Map();
            }
        }

        if (this.connection !== null) {
            try {
                await this.disposeConnection();
            } catch (error) {
                this.connection = null;
            }
        }

        this.reconnectCounter = 0;
        this.connectionOptions = {
            eventName: '',
            callback: null
        };
    }

    private async tryConnect(callback: any, eventName: string) {
        if (this.connection) {
            this.connectionPromise = null;

            if (this.connection.state === HubConnectionState.Connected) {
                return Promise.resolve();
            }

            if (this.connection.state === HubConnectionState.Disconnected) {
                await this.connection.stop();

                this.connection = null;
            }
        }

        this.connectionPromise = new Promise((resolve, reject) => {
            this.connectionResolve = resolve;
            this.connectionReject = reject;
        });

        this.connect(callback, eventName);

        return this.connectionPromise;
    }

    private async connect(callback: any, eventName: string): Promise<void> {
        if (!$user.isAuth) {
            // note: ignore/dispose if user was log out in the meantime
            return this.dispose();
        }
        
        try {
            this.logAttempt();

            this.connection = this.createConnection();

            await this.connection.start();

            this.handleSuccessfulConnect(this.connection, callback, eventName);
        } catch (error) {
            this.handleFailedConnect(callback, eventName, error);
        }
    }

    private async handleFailedConnect(callback: any, eventName: string, error: Error | any) {
        if (this.reconnectCounter >= $environment.signalRConfig.reconnectTries) {
            return this.connectionReject(SignalRMsg.CONNECTION_FAILED);
        }

        if (error.toString().includes(`Status code '401'`)) {
            this.logUnauthorized();
            await $settingsService.getSignalRSettings(true);
        }

        this.logFailure();

        this.reconnectCounter++;
        this.reconnectTimeout *= 2;

        window.setTimeout(this.connect.bind(this, callback, eventName), this.reconnectTimeout);
    }

    private handleSuccessfulConnect(connection: HubConnection, callback: any, eventName: string) {
        this.connectionOptions = {
            eventName,
            callback
        };

        connection.onclose((e) => this.onConnectionClose(e));

        connection.on(eventName, (data: string) => {
            let receiveData: any;

            receiveData = data;

            SignalREvent.dispatch('update', receiveData);
            callback(receiveData);
        });

        this.logSuccess();

        return this.connectionResolve();
    }

    private onConnectionClose(e: Error | undefined) {
        this.logDisconnected(e);
            
        this.activeSubscriptions = new Map();

        if ($user.isAuth && this.isEnabled) {
            this.handleLostConnection();
        }
    }

    private async handleLostConnection() {
        this.reconnectCounter = 0;
        this.reconnectTimeout = $environment.signalRConfig.reconnectTimeout || 1000;

        const { eventName, callback } = this.connectionOptions;

        try {
            await this.tryConnect(callback, eventName);

            this.handleSuccessfulReconnect();
        } catch (error) {
            $modalService.showSignalRModal(SignalRMsg.CONNECTION_LOST);
        }
    }

    private async register(viewName: string, viewTypes: string[]) {
        if (this.connection && this.connection.state === HubConnectionState.Connected) {
            try {
                const typePromises = viewTypes.map(type => this.registerViewType(viewName, type));
                await Promise.all(typePromises);
                this.saveNewSubscription(viewName, viewTypes);
            } catch (error) {
                $modalService.showSignalRModal(`Registration to ${viewName} failed`);
            }
        }
    }

    private saveNewSubscription(viewName: string, viewTypes: string[]) {
        if (this.activeSubscriptions.has(viewName) && this.subscriptionsHistory.has(viewName)) {
            return;
        }

        this.activeSubscriptions.set(viewName, viewTypes);
        this.subscriptionsHistory.set(viewName, viewTypes);
    }

    private registerViewType(viewName: string, viewType: string) {
        try {
            return this.activeConnection.invoke('RegisterConnectionOnView', 'view',
                viewName, viewType, settingsModule.getBusinessUnitName);
        } catch (error) {
            console.log(error);
        }
    }

    private async disposeConnection() {
        if (this.connection) {
            // todo: verify if 'UnregisterConnectionOnView' exists on the backend
            // const promises = this.subs.map(viewName => this.unregister(viewName));

            // await Promise.all(promises);

            this.connection.off(this.connectionOptions.eventName);

            try {
                await this.connection.stop();
            } catch (error) {
                console.log(error);
            }

            this.connection = null;
        }
    }

    private async unregister(viewName: string, viewTypes: string[]) {
        if (this.connection && this.activeSubscriptions.has(viewName)) {
            this.activeSubscriptions.delete(viewName);

            if (this.subscriptionsHistory.has(viewName)) {
                this.subscriptionsHistory.delete(viewName);
            }

            const typePromises = viewTypes.map(type => this.unregisterViewType(viewName, type));
            await Promise.all(typePromises);
        }
    }

    private async unregisterViewType(viewName: string, viewType: string): Promise<any> {
        const businessUnitName = settingsModule.getBusinessUnitName || '';

        return this.activeConnection.invoke('UnRegisterConnectionOnView', 'view',
            viewName, viewType, businessUnitName);
    }

    private createConnection(): signalR.HubConnection {
        const signalRConfig = $environment.signalRConfig;
        // set to true as requested by Allan
        signalRConfig.debug = true;

        const options: signalR.IHttpConnectionOptions = {
            logger: signalRConfig.debug ? LogLevel.Trace : LogLevel.None,
            accessTokenFactory: () => this.authToken
        };
        
        return new HubConnectionBuilder()
            .withUrl(this.url, options)
            .configureLogging(LogLevel.Trace)
            .build();
    }

    private handleSuccessfulReconnect() {
        this.logSuccess();

        this.subscriptionsHistory.forEach((viewTypes, viewName) => this.register(viewName, viewTypes));
    }

    private logAttempt() {
        if (this.reconnectCounter > 0) {
            const maxTries = $environment.signalRConfig.reconnectTries;
            this.logEventIfDebugging(`SignalR: ${this.getAttemptCounter()} of ${maxTries} attempts to reconnect...`);
        }
    }

    private logFailure() {
        if (this.reconnectCounter > 0) {
            this.logEventIfDebugging(`SignalR: ${this.getAttemptCounter()} attempt failed. Next try in ${this.reconnectTimeout}ms`);
        }
    }

    private logUnauthorized() {
        this.logEventIfDebugging(`SignalR: Authentication failed. Retrieving new token.`);
    }

    private logDisconnected(error: Error | undefined) {
        if (error) {
            this.logEventIfDebugging(`SignalR: Connection closed, error: ${error.message}`);
        }

        this.logEventIfDebugging(`SignalR: Connection closed, clearing ${this.activeSubscriptions.size} active view subscriptions.`);
    }

    private logSuccess() {
        if (this.reconnectCounter > 0) {
            this.logEventIfDebugging(`SignalR: ${this.getAttemptCounter()} attempt succeeded!`);
        }
    }

    private getAttemptCounter(): string {
        return this.reconnectCounter > 3 ? `${this.reconnectCounter}th` :
            this.reconnectCounter === 1 ? `${this.reconnectCounter}st` :
                this.reconnectCounter === 2 ? `${this.reconnectCounter}nd` :
                    `${this.reconnectCounter}rd`;
    }

    private logEventIfDebugging(event: string) {
        if ($environment.name !== 'production') {
            console.log(event);
        }
    }
}

export const signalRService = new SignalRService();
